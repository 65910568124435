.c-translate {
  text-align: left;
  position: relative;
  display: block;
  margin-top: rem(60px);

  &__content {
    font-weight: bold;
  }

  &__button {

    &-wrap {
      font-size: 0;
      display: flex;
      justify-content: flex-start;
    }
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // border: 1px solid transparent;
    font-size: 0;
    white-space: nowrap;
    -webkit-transition: background-color .3s cubic-bezier(.4, 0, .2, 1), color .45s cubic-bezier(.4, 0, .2, 1) 75ms;
    transition: background-color .3s cubic-bezier(.4, 0, .2, 1), color .45s cubic-bezier(.4, 0, .2, 1) 75ms;
    outline: 0;


    margin: 0;
    margin-top: rem(20px);


    background: #fff;
    border-radius: 5px 0 0 5px;
    color: inherit;

    font: inherit;
    line-height: normal;
    cursor: pointer;
    user-select: none;
    width: 10.625rem;
    height: 2.75rem;
    padding: 0 1.875rem;

    // @media(min-width:$from-medium) {
    //   width: 15.625rem;
    //   height: 3.75rem;
    //   padding: 0 1.875rem;

    // }

    &.-active {
      color: #fff;
      background-color: $accent;

      
    }

    &.-left {
      &::after {
        
        transform-origin: center right;
        border-radius: 5px 0 0 5px;
      }

      &:hover {
        &::after {
          border-radius: 5px 0 0 5px;

        }
      }
      

    }
    
    &.-right {
      border-left: none;
      border-radius: 0 5px 5px 0;
      &::after {
        border-radius: 0 5px 5px 0;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $accent-3;
      border-radius: 5px;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: center left;
      -ms-transform-origin: center left;
      transform-origin: center left;
      -webkit-transition: -webkit-transform .45s cubic-bezier(.4, 0, .2, 1) 75ms;
      transition: -webkit-transform .45s cubic-bezier(.4, 0, .2, 1) 75ms;
      transition: transform .45s cubic-bezier(.4, 0, .2, 1) 75ms;
      transition: transform .45s cubic-bezier(.4, 0, .2, 1) 75ms, -webkit-transform .45s cubic-bezier(.4, 0, .2, 1) 75ms;

     
    }

    &-label {
      display: inline-block;
      position: relative;
      transition: -webkit-transform .45s cubic-bezier(.4, 0, .2, 1);
      transition: transform .45s cubic-bezier(.4, 0, .2, 1);
      transition: transform .45s cubic-bezier(.4, 0, .2, 1), -webkit-transform .45s cubic-bezier(.4, 0, .2, 1);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      line-height: 1.4;
      z-index: 2;
      font-weight: bold;
      font-size: .75rem;

      &.-left {
        &:hover {
          transform: translateX(-0.5rem);
        }
      }

    }


    &:hover {
      transition-delay: 0s;
      color: #fff;
  
     
      &::after {
        background-color: $accent-3;
        transform: scaleX(1);
        transition-delay: 0;

        border-radius: 0 5px 5px 0;

       
      }
      .c-translate__button-label {
        transform: translateX(0.5rem);
        transition-delay: 75ms;
      }

    }

  }
}


.trans {
  display: none;
}

.trans.active {
  display: block;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

#maori p {
  position: relative;
  // font-size: 0;

  // &::after {
  //   content: 'He ohia ngāwari e noho nei hei tūāpapa mō tā mātou rautaki: ki te whakatinanahia ā mātou uara, ka angitu. Mā te ū ki ēnei mātāpono e whirinaki mai ai ngā hapori.';
  //   display: block;
  //   font-size: 1rem;
  //   line-height: 2;
  //   font-weight: normal;
  // }
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}